import { ERROR_PAGE_URL } from "../constants/urls.constants";
import * as SentryB from "@sentry/browser";
import * as Sentry from "@sentry/react";
import React from "react";

type Props = {};
type State = { hasError: boolean };

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    let is4xxError =
      error?.response?.status < 500 && error?.response?.status > 399;
    if (!is4xxError) {
      Sentry.withScope((scope) => {
        SentryB.setTag("user_id", localStorage.getItem("user_id"));
        scope.setExtra("componentStack", errorInfo);
        Sentry.captureException(error);
      });
    }
    // logger.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      window.location.href = ERROR_PAGE_URL;
      return <></>;
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
