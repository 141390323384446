// import { SegmentAnalytics } from '@segment/analytics.js-core';

declare global {
  interface Window {
    // @ts-ignore
    analytics: SegmentAnalytics.AnalyticsJS;
  }
}

// Initialize the library
// analytics.initialize(integrationSettings);

export const track = (name: string, properties: any = {}, callbackFn: () => void = () => {}) => {
  let userAgent = navigator.userAgent;
  properties['isDesktopWeb'] = userAgent.match(/Android/i) || userAgent.match(/iPhone/i) || userAgent.match(/iPad/i) ? false : true;
  window?.analytics?.track(name, properties, callbackFn);
  return null;
};

export const trackFailure = (name: string, err: any = {}, callbackFn: () => void = () => {}) => {
  let properties = {
      status: err?.response?.status,
      errorCode: err?.response?.data?.code,
      errorPayload: err?.response?.data || err?.message || err
  };
  window?.analytics?.track(name, properties, callbackFn);
};

export let identifyWithPhone = (email, phone) => {
  return window?.analytics?.identify(phone,{
    email: email,
    phone: phone
  });
};
