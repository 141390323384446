import { EVENT } from "../constants/events.constants";
import {
  ROUTE_BASE_PATH,
  ROUTE_SINGN_IN_PATH,
} from "../constants/route-paths.constants";
import { track } from "../utils/analytics";
import ClientStorage from "../utils/client-storage";
import {
  getCachedPhoneNumber,
  getNewSignInCommonEventProps,
  replaceAt,
  isProveFlowEnabled,
} from "../utils/helpers";
import { IconPhone } from "./IconPhone";
import { TempButton } from "@dfc-web/ui";
import { MouseEvent, useEffect } from "react";

type props = {
  buttonsInRow?: boolean;
  buttonsParentClass?: string;
  applyButtonClass?: string | boolean | null;
  signInButtonClass?: string | boolean | null;
  showOnlySignIn?: boolean | null;
  showOnTop?: boolean | null;
  showApplyBtn?: boolean | null;
};

export function NewSignInFlowActions({
  buttonsInRow,
  buttonsParentClass,
  applyButtonClass,
  signInButtonClass,
  showOnlySignIn = false,
  showOnTop = false,
  showApplyBtn = true,
}: props) {
  const appConfig = window["appConfig"];
  const phoneNumber = getCachedPhoneNumber();
  const QRCodeImageURL = window["appConfig"]["PROVE_FLOW_URL"];

  const clearLocalData = () => {
    ClientStorage.clearLocal({ exclude: ["CUSTOMER_AUTH_LOGIN_IDENTIFIER"] });
  };

  const useDfferentAccount = (evt: MouseEvent<HTMLElement>) => {
    clearLocalData();
    track(EVENT.dfc_card_overview_page, {
      ...getNewSignInCommonEventProps(),
      deletedPhoneNumber: ClientStorage.getLocal("phone_number"),
    });
    window.location.href =
      appConfig.PARTNER_NAME === "deserve"
        ? "/deserve_card_overview"
        : ROUTE_BASE_PATH;
  };

  const trackClick = (evtName: string) => {
    track(evtName, {
      ...getNewSignInCommonEventProps(),
    });
  };

  useEffect(() => {
    ClientStorage.deleteLocal("phone_otp_code");
  }, []);

  return (
    <>
      <div
        className={`x-sm:flex md:flex ${
          showOnTop ? "" : "lg:block"
        } justify-around mt-4 ${buttonsInRow ? "flex" : ""}`}
      >
        {(showOnTop || (!phoneNumber && !showOnlySignIn)) &&
          appConfig.PARTNER_NAME !== "oppfi" &&
          appConfig.PARTNER_NAME !== "earnest" &&
          showApplyBtn && (
            <div
              className={`w-64 x-sm:mt-0 x-sm:px-0 x-sm:flex x-sm:justify-center sm:justify-start ${
                buttonsParentClass || "w-64 x-sm:w-36"
              } ${buttonsInRow ? "mt-0" : "mt-6"}`}
            >
              <a
                onClick={() => trackClick(EVENT.dfc_auth_apply_now_clicked)}
                href={
                  isProveFlowEnabled() ? QRCodeImageURL : ROUTE_SINGN_IN_PATH
                }
                className={`${
                  applyButtonClass || "sign-apply-btn"
                } text-center items-center py-3 px-6 rounded-full justify-center block x-sm:w-full`}
                data-test-id="apply-now-link"
              >
                Apply Now
              </a>
              {/* TODO: remove afte test  */}
              {/* <TempButton>Apply Now</TempButton> */}
            </div>
          )}

        <div
          className={`x-sm:mt-0 x-sm:px-0 x-sm:flex x-sm:justify-center ${
            buttonsParentClass || "w-64"
          } ${phoneNumber ? "x-sm:w-64 md:mx-auto" : "x-sm:w-36"} ${
            buttonsInRow ? "mt-0" : "mt-6 lg:mt-2"
          } ${isProveFlowEnabled() && phoneNumber ? "lg:mx-auto" : "lg:mx-0"}`}
        >
          <a
            onClick={() => trackClick(EVENT.dfc_auth_signin_clicked)}
            href={`${ROUTE_SINGN_IN_PATH}?flow=signin${
              phoneNumber ? "&phone=" + encodeURIComponent(phoneNumber) : ""
            }`}
            className={`${
              showOnTop || !phoneNumber
                ? signInButtonClass || "sign-btn justify-center font-sf-display"
                : "sign-apply-btn justify-start"
            } text-center block py-3 px-6 rounded-full cursor-pointer items-center x-sm:w-full ${
              isProveFlowEnabled() && phoneNumber ? "mx-auto" : ""
            }`}
            data-test-id="sign-in-link"
          >
            {!showOnTop && phoneNumber && (
              <span className="pr-2">
                <IconPhone />
              </span>
            )}
            <span>
              {!showOnTop && phoneNumber
                ? replaceAt(phoneNumber, 2, " XXX XXX ")
                : "Sign In"}
            </span>
          </a>
        </div>
      </div>
      {phoneNumber && !buttonsInRow && (
        <div
          className={`${
            buttonsParentClass || "w-64"
          } x-sm:w-full flex justify-center mt-4 md:mx-auto ${
            isProveFlowEnabled() ? "lg:mx-auto" : "lg:mx-0"
          }`}
        >
          <span className="border block sign-btn-devider"></span>
        </div>
      )}
      {!showOnTop && phoneNumber && (
        <div
          className={`mt-3 x-sm:px-4 x-sm:flex x-sm:justify-center md:text-center ${
            isProveFlowEnabled() ? "lg:text-center" : "lg:text-left"
          }`}
        >
          <div
            onClick={useDfferentAccount}
            className={`${
              buttonsParentClass || "w-64"
            } text-center sign-in-diff-option inline-flex items-center rounded-full justify-center cursor-pointer`}
          >
            <span className="uppercase text-xs">Or use different account</span>
          </div>
        </div>
      )}
    </>
  );
}
